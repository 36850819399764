// Global packages and components
import AnimateHeight from 'react-animate-height'
import { clsx } from 'clsx'
import { ReactNode, Children, isValidElement, cloneElement } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useAccordion } from '~/hooks'

// Types
import type { Panel } from '~/hooks/useAccordion'

type StyleOptions = 'default' | 'minimal'
type TitleSizeOptions = 'sm' | 'md' | 'lg'
type TitleWeightOptions = 'normal' | 'semibold' | 'bold'

interface SectionProps {
  title: string
  children: ReactNode
  className?: string
  index?: number
  isOpen?: boolean
  sectionStyle?: StyleOptions
  titleSize?: TitleSizeOptions
  titleWeight?: TitleWeightOptions
  toggle?: (e: number) => void
}

interface MainProps {
  /**
   * Accordion <Section> elements
   */
  children: ReactNode
  /**
   * Custom styling set options
   */
  sectionStyle?: StyleOptions
  /**
   * Sizing for the accordion title
   */
  titleSize?: TitleSizeOptions
  /**
   * Sizing for the accordion title
   */
  titleWeight?: TitleWeightOptions
}

// Individual sections
const Section = ({
  title,
  children,
  className,
  index,
  isOpen = false,
  sectionStyle,
  titleSize,
  titleWeight,
  toggle,
}: SectionProps) => (
  <div className={className}>
    <button
      type="button"
      className={clsx(
        'flex w-full items-center justify-between text-left transition-all',
        {
          'rounded-xl': !isOpen && sectionStyle == 'default',
          'rounded-t-xl border-l border-r border-t border-teal-green':
            isOpen && sectionStyle == 'default',
          'bg-neutral-150 p-5': sectionStyle == 'default',
          'border-b border-neutral-200 py-3': sectionStyle == 'minimal',
        }
      )}
      onClick={() => (toggle && index ? toggle(index) : undefined)}
    >
      <span
        className={clsx('flex-1 pr-3', {
          'text-lg': titleSize == 'md',
          'text-xl': titleSize == 'lg',
          'font-semibold': titleWeight == 'semibold',
          'font-bold': titleWeight == 'bold',
          'text-teal-green': isOpen && sectionStyle == 'default',
        })}
      >
        {title}
      </span>
      <ChevronDownIcon
        className={clsx('size-6 flex-none transition-transform', {
          'rotate-180': isOpen,
          'text-teal-green': isOpen && sectionStyle == 'default',
        })}
      />
    </button>
    <AnimateHeight
      duration={300}
      height={isOpen ? 'auto' : 0}
      animateOpacity
    >
      <div
        className={clsx('rounded-b-xl', {
          'border-b border-l border-r border-teal-green':
            isOpen && sectionStyle == 'default',
          'bg-neutral-150 px-5 pb-5': sectionStyle == 'default',
          'py-5': sectionStyle == 'minimal',
        })}
      >
        {children}
      </div>
    </AnimateHeight>
  </div>
)

// Main export
const Accordion = ({
  children,
  sectionStyle = 'default',
  titleSize = 'sm',
  titleWeight = 'semibold',
}: MainProps) => {
  const { panels, togglePanels } = useAccordion({ children })

  return Children.map(children, (child, i) => {
    if (isValidElement(child)) {
      const updatedProps = {
        ...child.props,
        index: i + 1,
        isOpen: panels.some((p: Panel) => p.index === i + 1 && p.open),
        sectionStyle: sectionStyle,
        titleSize: titleSize,
        titleWeight: titleWeight,
        toggle: togglePanels,
      }

      return cloneElement(child, updatedProps)
    }

    return child
  })
}

Accordion.Section = Section

export default Accordion
