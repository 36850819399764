// Global packages and components
import { Slider, Heading, BlogListerItem } from '~/components'

// Types
import type { SanityCustomImage, SanitySlug } from '~/types'

interface BlockPostCarouselProps {
  title?: string
  items: {
    title: string
    slug: SanitySlug
    excerpt: string
    image: SanityCustomImage
  }[]
}

// Main export
export default function BlockPostCarousel({
  title,
  items,
}: BlockPostCarouselProps) {
  return items && items.length !== 0 ? (
    <div className="container">
      {title && (
        <Heading
          className="mb-10 text-center"
          tag="h2"
          size="h4"
        >
          {title}
        </Heading>
      )}
      <Slider
        className="splide__pagination-inline -mx-4"
        perPage={1}
        options={{
          breakpoints: {
            768: {
              perPage: 2,
            },
            1024: {
              perPage: 3,
            },
          },
        }}
      >
        {items.map((e, i) => (
          <div
            className="px-4"
            key={i}
          >
            <BlogListerItem
              key={i}
              {...e}
            />
          </div>
        ))}
      </Slider>
    </div>
  ) : null
}
