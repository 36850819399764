// Global packages, functions and components
import { Link } from '@remix-run/react'
import { Button, Heading, BannerImage } from '~/components'
import { getExcerpt } from '~/utils'

// Types
import type { BlogListingItem } from '~/types'

// Main export
const BlogListerItem = ({ slug, title, image, excerpt }: BlogListingItem) => (
  <Link
    to={`/blog/${slug.current}`}
    className="group relative block aspect-square overflow-hidden rounded-2xl"
  >
    <div className="absolute left-0 top-0 z-[3] flex size-full flex-col items-start justify-end p-5 text-white lg:p-8">
      <Heading
        tag="h3"
        size="h5"
        className="mb-2"
      >
        {title}
      </Heading>
      <p className="mb-4">{getExcerpt(excerpt, 120)}</p>
      <Button
        title="Learn more"
        style="vibrantGreen"
        hasArrow
        mimic
      />
    </div>
    <BannerImage image={image} />
  </Link>
)

export default BlogListerItem
