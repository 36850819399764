// Global packages and components
import {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  Children,
  isValidElement,
} from 'react'

// Types
export type Panel = { open: boolean; index: number }

interface HookProps {
  children: ReactNode
}

// Main export
const useAccordion = ({ children }: HookProps) => {
  // Array to save panel open states
  const [panels, setPanels] = useState<Array<Panel>>([])

  // Setup panels object
  const setupPanels = useCallback(() => {
    setPanels(prevState =>
      Children.toArray(children)
        .filter(isValidElement)
        .map((child: any, i) => ({
          open: prevState[i]?.open
            ? prevState[i].open
            : child.props?.isOpen
              ? child.props.isOpen
              : false,
          index: i + 1,
        }))
    )
  }, [children])

  // Function to close all panels except the one clicked
  const togglePanels = (index: number) => {
    setPanels(
      panels.map(p => ({
        open: p.index == index ? !p.open : false,
        index: p.index,
      }))
    )
  }

  // On load populate our state with children data
  useEffect(() => {
    setupPanels()
  }, [setupPanels])

  return {
    panels,
    togglePanels,
  }
}

export default useAccordion
